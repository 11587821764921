<template>
  <div
    v-if="shouldDisplayRefurbishedPriceLabel && shouldDisplayOriginalPrice"
    class="body-2-bold"
  >
    {{ i18n(translations.productRefurbishedPriceLabel) }}
  </div>
</template>

<script lang="ts" setup>
/**
 * Displays the mention that the price displayed is the one of a refurbished device when displaying the striked price new.
 * This is legally required in Japan that we make it extra clear that the price displayed is not the one of a new device when comparing to price new.
 */
import { computed } from 'vue'

import { type Price } from '@backmarket/http-api'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'
import { isEmpty } from '@backmarket/utils/object/isEmpty'

import { COUNTRIES_WITH_REBURBISHED_PRICE_LABEL } from '../../Price.constants'

import translations from './RefurbishedPrice.translations'

const props = defineProps<{
  priceWhenNew?: Price
}>()

const i18n = useI18n()
const {
  market: { countryCode },
} = useMarketplace()

const shouldDisplayRefurbishedPriceLabel = computed(() => {
  return COUNTRIES_WITH_REBURBISHED_PRICE_LABEL.includes(countryCode)
})

const shouldDisplayOriginalPrice = computed(() => {
  return !isEmpty(props.priceWhenNew)
})
</script>
