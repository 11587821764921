<template>
  <div v-if="displayLoader" class="mt-6 flex items-center justify-center">
    <RevLoadingScreen :text="loadingText" />
  </div>
  <template v-else>
    <div>
      <RevButtonBase @click="$emit('exit')">
        {{ i18n(translations.exit) }}
      </RevButtonBase>
      <TradeInSearchBar
        v-if="model"
        :initial-query="model"
        @default-item-selected="() => $emit('default-item-selected')"
        @item-selected="(item) => $emit('item-selected', item)"
      />
    </div>
    <div class="text-static-brand-mid mb-16 flex items-center">
      <RevButtonBase v-if="displayFunnel" @click="handleBack">
        <IconArrowLeft
          :aria-label="i18n(modalBuybackTranslations.backButtonLabel)"
          size="medium"
        />
      </RevButtonBase>
      <h2 class="body-1 m-auto">{{ title }}</h2>
    </div>
    <Categories
      v-if="displayCategories"
      :categories
      class="mt-7"
      variant="embedded"
      @next-step="getCategoryFunnel"
    />
    <div v-if="displayFunnel">
      <QuestionsForm
        :active-step="activeQuestionsStep"
        :auto-next="showFunnelButton ? false : true"
        class="mb-72"
        :funnel
        :has-submit-button="false"
        :isLoadingOffer="isPostingAnswers"
        variant="embedded"
        @next-question="getNextQuestion"
        @next-step="getNextStep"
        @submit-answers="handlePostAnswers"
      />

      <div v-if="showFunnelButton" class="bg-surface-default-mid m-24">
        <RevButton
          :form="QUESTIONS_FORM_ID"
          full-width="always"
          type="submit"
          variant="primary"
        >
          {{ i18n(translations.continue) }}
        </RevButton>
      </div>
    </div>
  </template>
</template>

<script setup lang="ts">
import { computed, inject, onBeforeUnmount, ref, watch, watchEffect } from 'vue'
import { type LocationQuery } from 'vue-router'

import {
  type GetOfferV1Response,
  getOfferV1,
} from '@backmarket/http-api/src/api-specs-buyback/customer/getOfferV1'
import {
  type FunnelStep,
  type GetQuestionsResponse,
  type Question,
} from '@backmarket/http-api/src/api-specs-buyback/customer/getQuestionsV3'
import { HttpApiError } from '@backmarket/http-api/src/utils/HttpApiError'
import modalBuybackTranslations from '@backmarket/nuxt-layer-buyback/utils/Modal.translations'
import { $httpFetch as $httpFetchv1 } from '@backmarket/nuxt-module-http/$httpFetch'
import { useHttpFetch } from '@backmarket/nuxt-module-http-v2/useHttpFetch'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useLogger } from '@backmarket/nuxt-module-logger/useLogger'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { isEmpty } from '@backmarket/utils/object/isEmpty'
import { RevButton } from '@ds/components/Button'
import { RevButtonBase } from '@ds/components/ButtonBase'
import { RevLoadingScreen } from '@ds/components/LoadingScreen'
import { IconArrowLeft } from '@ds/icons/IconArrowLeft'

import QuestionsForm, {
  type NextQuestionPayload,
  type NextStepPayload,
  type SubmitAnswersPayload,
} from '~/scopes/buyback/components/QuestionsForm/QuestionsForm.vue'
import { QUESTIONS_FORM_ID } from '~/scopes/buyback/components/QuestionsForm/constants'
import type { ErrorData } from '~/scopes/buyback/components/TheCatcher/useCatcher'
import type { BuybackProduct } from '~/scopes/buyback/composables/useBuybackSearch'
import Categories from '~/scopes/buyback/swap/components/Categories/Categories.vue'

import { STEPS, SWAP_INFO_MESSAGE } from '../SwapModal/constants'
import TradeInSearchBar from '../TradeInSearchBar/TradeInSearchBar.vue'

import translations from './StepQuestions.translations'

type Steps = (typeof STEPS)[keyof typeof STEPS]
const i18n = useI18n()
const logger = useLogger()

const { trackClick } = useTracking()
const $httpFetch = useHttpFetch()

const props = withDefaults(
  defineProps<{
    initialPayload?: LocationQuery
    datalayerCategory: string
    zone: string
  }>(),
  {
    initialPayload: undefined,
  },
)

const emit = defineEmits<{
  (e: 'item-selected', value: BuybackProduct): void
  (e: 'default-item-selected'): void
  (e: 'on-finish'): void
  (e: 'exit'): void
  (e: 'save-no-offer'): void
  (e: 'save-offer', offer: GetOfferV1Response, form: LocationQuery): void
}>()

const getInitialStep = () => {
  if (!isEmpty(props.initialPayload)) {
    return STEPS.QUESTIONS
  }

  return STEPS.CATEGORIES
}

const step =
  ref<Exclude<Steps, 'no_offer' | 'intro' | 'offer'>>(getInitialStep())

const funnel = ref<Array<FunnelStep>>([])
const categories = ref<Question | Record<string, never>>({})
const showFunnelButton = ref(false)
const isLoadingCategories = ref(false)
const isLoadingInitialPayload = ref(false)
const isPostingAnswers = ref(false)
const form = ref<LocationQuery>({})
const offer = ref<GetOfferV1Response>()
const errorData = inject<ErrorData>('errorData', ref({ message: '', step: '' }))
errorData.value = {
  message: SWAP_INFO_MESSAGE.SWAP_EMBEDDED_MODAL,
  step: step.value,
}

const activeQuestionsStep = computed(() => {
  const steps = funnel.value.map(
    ({ step: questionFunnelStep }) => questionFunnelStep,
  )

  return steps.find((questionFunnelStep) => questionFunnelStep?.active) || null
})

const titles = computed(() => {
  return {
    [STEPS.CATEGORIES]: i18n(translations.categoriesTitle),
    [STEPS.QUESTIONS]: activeQuestionsStep.value?.label ?? '',
  }
})

const title = computed(() => {
  return titles.value[step.value]
})

const displayLoader = computed(() => {
  return (
    isLoadingCategories.value ||
    isPostingAnswers.value ||
    isLoadingInitialPayload.value
  )
})

const loadingText = computed(() => {
  return isPostingAnswers.value ? i18n(translations.loadingOfferText) : ''
})

const displayCategories = computed(() => {
  return step.value === STEPS.CATEGORIES
})

const displayFunnel = computed(() => {
  return step.value === STEPS.QUESTIONS
})

const model = computed(() => {
  if (!form.value?.model || Array.isArray(form.value.model)) {
    return ''
  }

  return form.value.model
})

function handleTracking(label: string) {
  trackClick({
    value: {
      category: props.datalayerCategory,
      ...form.value,
      // eslint-disable-next-line camelcase
      swap_estimation: i18n.price(offer.value?.listing.price || ''),
    },
    name: label,
    zone: props.zone,
  })
}

async function getQuestions({
  formPayload = {},
}:
  | { formPayload: LocationQuery }
  | Record<string, never> = {}): Promise<GetQuestionsResponse | null> {
  const questionsResponse = await $httpFetch(
    '/buyback-funnel/api/v1/funnel/:kind/questions',
    {
      pathParams: {
        kind: 'swap',
      },
      query: {
        ...formPayload,
        embedded: 'true',
      },
    },
  )

  if (isEmpty(questionsResponse)) {
    logger.error(SWAP_INFO_MESSAGE.EMPTY_QUESTIONS, {
      error: new Error('Empty response from questions API'),
      info: { ...formPayload },
      owners: ['bot-squad-circularity-customer-front'],
    })
  }

  return questionsResponse
}

async function fetchQuestions({ nextStep = 'true', formPayload = {} }) {
  try {
    form.value = {
      ...form.value,
      ...formPayload,
      nextStep,
    }

    const payload = await getQuestions({
      formPayload: form.value,
    })
    funnel.value = payload?.funnel || []
  } catch (err) {
    const error = err as Error
    logger.error(SWAP_INFO_MESSAGE.GET_QUESTIONS, {
      error,
      owners: ['bot-squad-circularity-customer-front'],
    })
  }
}

async function getNextQuestion({
  formPayload = {},
  reset = [],
}: NextQuestionPayload): Promise<void> {
  if (reset.length) {
    reset.forEach((key) => {
      delete form.value[key]
    })
  }
  await fetchQuestions({
    formPayload,
    nextStep: showFunnelButton.value ? 'false' : 'true',
  })
}

async function getNextStep({ formPayload }: NextStepPayload) {
  showFunnelButton.value = false

  await fetchQuestions({
    formPayload,
    nextStep: 'true',
  })
}

async function handlePostAnswers({ formPayload }: SubmitAnswersPayload) {
  form.value = {
    ...form.value,
    ...formPayload,
  }
  isPostingAnswers.value = true

  // Wait 800 before fetching the offer in order to
  // give customers the feeling that we are 'really' searching
  // (meanwhile a screen loader with additional information is shown)
  await new Promise((resolve) => {
    setTimeout(resolve, 800)
  })

  try {
    offer.value = await $httpFetchv1(getOfferV1, {
      queryParams: {
        ...form.value,
      },
      pathParams: {
        kind: 'swap',
      },
    })
  } catch (error) {
    const httpError = error as HttpApiError

    if (httpError.status === 404) {
      handleTracking('scarabee')
    } else {
      logger.error(SWAP_INFO_MESSAGE.GET_OFFER, {
        httpError,
        owners: ['bot-squad-circularity-customer-front'],
      })
    }
  } finally {
    isPostingAnswers.value = false
  }
  // Auto accepting the trade-in offer
  try {
    if (offer.value) {
      emit('save-offer', offer.value, form.value)

      handleTracking('swap_offer')
    } else {
      emit('save-no-offer')
    }
  } catch (err) {
    const error = err as Error
    logger.error(SWAP_INFO_MESSAGE.ADD_SWAP, {
      error,
      owners: ['bot-squad-circularity-customer-front'],
    })
  }
}

function resetFunnel(): void {
  funnel.value = []
  form.value = {}
  offer.value = undefined
  categories.value = {}
  step.value = STEPS.QUESTIONS
  showFunnelButton.value = false
}

async function getCategories(): Promise<void> {
  handleTracking('buyback_estimate')

  isLoadingCategories.value = true

  try {
    const payload = await getQuestions()

    const firstStepQuestion = payload?.funnel || []

    categories.value = firstStepQuestion[0].questions[0]
    step.value = STEPS.CATEGORIES
  } catch (err) {
    const error = err as Error
    logger.error(SWAP_INFO_MESSAGE.GET_CATEGORIES, {
      error,
      owners: ['bot-squad-circularity-customer-front'],
    })
  } finally {
    isLoadingCategories.value = false
  }
}

async function getCategoryFunnel(category: { category: string }) {
  try {
    form.value = {
      ...category,
    }

    handleTracking('category')
    const payload = await getQuestions({
      formPayload: form.value,
    })
    funnel.value = payload?.funnel || []
    step.value = STEPS.QUESTIONS
  } catch (err) {
    const error = err as Error
    logger.error(SWAP_INFO_MESSAGE.GET_QUESTIONS, {
      error,
      owners: ['bot-squad-circularity-customer-front'],
    })
  }
}

async function goToCategories() {
  if (isEmpty(categories.value)) {
    await getCategories()
  }
  step.value = STEPS.CATEGORIES
}

async function getFunnelStepFromHistory(): Promise<void> {
  const isFirstQuestionStep =
    funnel.value.findIndex(
      ({ step: questionFunnelStep }) => questionFunnelStep?.active,
    ) === 0

  if (isFirstQuestionStep) {
    showFunnelButton.value = false
    await goToCategories()

    return
  }

  try {
    const activeFunnelQuestionsStep = funnel.value.find(
      ({ step: questionFunnelStep }) => questionFunnelStep?.active,
    )
    const keysToDelete = activeFunnelQuestionsStep?.questions.map(
      (question) => question.key,
    )

    keysToDelete?.forEach((key) => {
      delete form.value[key]
    })

    form.value = {
      ...form.value,
      nextStep: 'false',
    }

    const payload = await getQuestions({ formPayload: form.value })
    funnel.value = payload?.funnel || []
  } catch (err) {
    const error = err as Error
    logger.error(SWAP_INFO_MESSAGE.GET_QUESTIONS, {
      error,
      owners: ['bot-squad-circularity-customer-front'],
    })
  }
}

async function handleBack(): Promise<void> {
  showFunnelButton.value = true
  handleTracking('funnel_arrow_back')

  if (step.value === STEPS.QUESTIONS) {
    await getFunnelStepFromHistory()
  }
}

watch(
  [() => props.initialPayload],
  async ([newInitialPayload]) => {
    step.value = getInitialStep()
    if (!isEmpty(newInitialPayload)) {
      isLoadingInitialPayload.value = true
      await getNextQuestion({ formPayload: newInitialPayload })
      isLoadingInitialPayload.value = false
    } else {
      await getCategories()
    }
  },
  { immediate: true },
)

watch(form, (newForm) => {
  if (newForm && activeQuestionsStep.value && !showFunnelButton.value) {
    handleTracking(activeQuestionsStep.value.key)
  }
})

watchEffect(() => {
  if (step.value) {
    logger.info(SWAP_INFO_MESSAGE.VIEW_SWAP_STEP, {
      step: step.value,
      owners: ['bot-squad-circularity-customer-front'],
    })
  }
})

onBeforeUnmount(() => {
  resetFunnel()
  emit('on-finish')
})
</script>
