<template>
  <div>
    <RefurbishedPrice />
    <div class="flex flex-wrap items-baseline gap-x-4">
      <span
        class="heading-2"
        data-qa="productpage-product-price"
        data-test="productpage-product-price"
      >
        {{ i18n.price(price) }}
      </span>
      <PriceLabel
        v-if="selectedOffer.price"
        :buyback-offer
        :price="selectedOffer.price"
        :price-due-today
      />
    </div>

    <div class="flex flex-wrap items-center gap-x-8">
      <OriginalPrice
        v-if="product.type !== 'Brand new'"
        :is-discount-reassurance-displayed="Boolean(selectedOffer.discount)"
        :price-when-new="product.priceWhenNew"
      />
      <RevTag
        v-if="product.type === 'Brand new'"
        :label="i18n(translations.newTaglabel)"
        variant="info"
      />

      <YouSave
        v-if="selectedOffer.price"
        :buyback-offer
        :is-discount-reassurance-displayed="Boolean(selectedOffer.discount)"
        :mobile-plan
        :price="selectedOffer.price"
        :price-when-new="product.priceWhenNew"
      />

      <InStock />
    </div>

    <div class="flex flex-wrap items-center gap-x-8">
      <VatIncluded class="mt-4" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { type Price } from '@backmarket/http-api'
import type { MobilePlanOffer } from '@backmarket/http-api/src/api-specs-b2c-services/mobile-plan/types/mobile-plan-offers'
import { type GetPickersResponse } from '@backmarket/http-api/src/api-specs-navigation-experience/product/pickers'
import { type GetProductResponse } from '@backmarket/http-api/src/api-specs-navigation-experience/product/product'
import { useBuybackOffer } from '@backmarket/nuxt-layer-buyback/composables/buybackOffer/useBuybackOffer'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevTag } from '@ds/components/Tag'

import translations from './Price.translations'
import InStock from './components/InStock/InStock.vue'
import OriginalPrice from './components/OriginalPrice/OriginalPrice.vue'
import PriceLabel from './components/PriceLabel/PriceLabel.vue'
import RefurbishedPrice from './components/RefurbishedPrice/RefurbishedPrice.vue'
import VatIncluded from './components/VatIncluded/VatIncluded.vue'
import YouSave from './components/YouSave/YouSave.vue'

withDefaults(
  defineProps<{
    selectedOffer: NonNullable<GetPickersResponse['selectedOffer']>
    price: Price
    priceDueToday?: Price | null
    product: GetProductResponse
    mobilePlan?: MobilePlanOffer
  }>(),
  {
    mobilePlan: undefined,
    priceDueToday: undefined,
  },
)

const i18n = useI18n()
const { buybackOffer } = useBuybackOffer()
</script>
