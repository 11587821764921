<template>
  <!-- eslint-disable vuejs-accessibility/mouse-events-have-key-events vuejs-accessibility/no-static-element-interactions -->
  <aside
    class="relative flex h-[3rem] max-h-[3rem] w-full flex-col gap-8 overflow-hidden"
    @mouseenter="pauseCarousel"
    @mouseleave="resumeCarousel"
  >
    <TransitionGroup
      class="relative h-full w-full"
      css
      :enter-active-class="tw`transition-all duration-300 ease-in-out`"
      :enter-from-class="tw`translate-y-[3rem] opacity-0`"
      :enter-to-class="tw`translate-y-0 opacity-100`"
      :leave-active-class="
        tw`transition-all duration-300 ease-in-out absolute top-0 left-0 w-full`
      "
      :leave-to-class="tw`translate-y-[-3rem] opacity-0`"
      tag="div"
    >
      <template v-for="(review, index) in reviewsList" :key="review.id">
        <div
          v-show="index === currentReviewIndex"
          class="relative h-[3rem] max-h-[3rem] w-full overflow-hidden"
        >
          <SplitComment :review />
        </div>
      </template>
    </TransitionGroup>
  </aside>
</template>

<script setup lang="ts">
import { onBeforeUnmount, onMounted, ref } from 'vue'

import { tw } from '@backmarket/utils/string/tw'

import type { DisplayedReview } from '~/scopes/reviews/reviews-display/models/reviews'

import SplitComment from './SplitComment.vue'

const { reviewsList } = defineProps<{
  reviewsList: DisplayedReview[]
}>()

const currentReviewIndex = ref(0)
let carouselInterval: ReturnType<typeof setInterval> | null = null
const isPaused = ref(false)

const ANIMATION_INTERVAL = 5000
function startCarousel() {
  if (reviewsList.length <= 1) return

  carouselInterval = setInterval(() => {
    currentReviewIndex.value =
      (currentReviewIndex.value + 1) % reviewsList.length
  }, ANIMATION_INTERVAL)
}

function pauseCarousel() {
  if (carouselInterval) {
    clearInterval(carouselInterval)
    carouselInterval = null
    isPaused.value = true
  }
}

function resumeCarousel() {
  if (isPaused.value) {
    startCarousel()
    isPaused.value = false
  }
}

onMounted(() => {
  startCarousel()
})

onBeforeUnmount(() => {
  if (carouselInterval) {
    clearInterval(carouselInterval)
  }
})
</script>
